import * as snippet from '@segment/snippet'

import { getPreferences } from '@helpers/analytics/preferences'
import { SEGMENT_KEY } from '@helpers/constants'

export default function renderSegmentSnippet(anonymousId) {
  const opts = {
    apiKey: SEGMENT_KEY,
    page: true
  }

  const { destinationPreferences } = getPreferences()
  const hasUserDisabledSegment = destinationPreferences['Segment.io'] === false
  if (hasUserDisabledSegment) opts.load = false

  let snippetContent = process.env.NODE_ENV !== 'production' ? snippet.max(opts) : snippet.min(opts)
  if (anonymousId) {
    const needle = 'analytics.page();'
    const breakingPoint = snippetContent.indexOf(needle) + needle.length
    const startOfSnippet = snippetContent.slice(0, breakingPoint)
    const endOfSnippet = snippetContent.slice(breakingPoint)

    snippetContent = `${startOfSnippet}\nanalytics.setAnonymousId("${anonymousId}");${endOfSnippet}`
  }
  return snippetContent
}
