/**
 * Action types for cart updates
 */

export default {
  CART_EXPIRED: 'CART_EXPIRED',
  CART_SIZE_MISMATCH: 'CART_SIZE_MISMATCH',
  CLEAR_CART: 'CLEAR_CART',
  CLEAR_CART_MESSAGE: 'CLEAR_CART_MESSAGE',
  INITIALIZE_CHECKOUT: 'INITIALIZE_CHECKOUT',
  RECEIVE_CART_PRODUCTS: 'RECEIVE_CART_PRODUCTS',
  REQUEST_CART_PRODUCTS: 'REQUEST_CART_PRODUCTS',
  SET_CART_ID: 'SET_CART_ID',
  SET_CART_MESSAGE: 'SET_CART_MESSAGE',
  SET_CART_ITEMS: 'SET_CART_PRODUCTS',
  UPDATE_CART_PRODUCTS: 'UPDATE_CART_PRODUCTS'
}
