import { DEFAULT_MENU_SLUG } from '@helpers/constants'

import t from './actionTypes'

// products is the list of products in a catalogue based on a location,
// product is a single product, one focused in a /products/:id view
export const defaultState = {
  [DEFAULT_MENU_SLUG]: {
    products: {},
    catalogItem: {}
  },
  error: false,
  products: {},
  groups: {},
  loading: false,
  menus: {},
  announcements: [],

  // TODO: REMOVE THESE
  catalogItem: {}, // Only used in SEO pages. Relocate to container state
  isSidebarOpen: false, // Sidebar can keep track of it's own UI state
  product: {}, // --> This is redundant data. The product should exist in `products` or it can be fetched and placed there
  // -----------------------//
  currentMenuEnabled: true //
}

const menu = (state = defaultState, action) => {
  switch (action.type) {
    case t.REQUEST_PRODUCTS:
      return state

    case t.REQUEST_PRODUCT:
      return state

    case t.SET_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          ...action.payload
        }
      }

    case t.SET_MENUS:
      return {
        ...state,
        menus: {
          ...state.menus,
          ...action.payload
        }
      }

    case t.MENU_NOT_ENABLED:
      return {
        ...state,
        currentMenuEnabled: false
      }

    case t.SET_CATALOG_ITEM:
      return {
        ...state,
        // TODO: this fixes recommended groups, however it is duplicated in the menu slug below I think because of SEO pages.
        // Investigate whether or not we need catalogItem inside a keyed menu
        catalogItem: action.catalogItem,
        [DEFAULT_MENU_SLUG]: {
          ...state.DEFAULT_MENU_SLUG,
          catalogItem: action.catalogItem,
          products: {
            ...state.products,
            [action.catalogItem.id]: action.catalogItem
          }
        }
      }

    case t.CLEAR_CATALOG_ITEM:
      return {
        ...state,
        catalogItem: {}
      }

    case t.SET_PRODUCT:
      return {
        ...state,
        product: action.product
      }

    case t.OPEN_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: true
      }

    case t.CLOSE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: false
      }

    case t.SET_ANNOUNCEMENTS:
      // For now, only set one announcement until we dig further into why 2 announcements
      // break things. Also, make sure we don't create [undefined] arrays.
      // eslint-disable-next-line
      const announcements = action.announcements?.[0] ? [action.announcements[0]] : []

      return {
        ...state,
        announcements
      }

    default:
      return state
  }
}

export default menu
