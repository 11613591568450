import t from './actionTypes'

export const defaultState = {
  code: '',
  amount: 0,
  error: false,
  open: false,
  pending: false,
  removed: false,
  success: false
}

const promoReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.OPEN_APPLY_PROMO:
      return {
        ...state,
        open: true,
        success: false
      }

    /**
     * This action is fired right before the POST request to let the UI know we're posting data
     */
    case t.APPLY_PROMO_SEND:
      return {
        ...state,
        pending: true,
        error: false,
        success: false,
        amount: 0
      }

    /**
     * This action is fired when the input value changes in apply promo to persist the value
     */
    case t.SET_PROMO:
      return {
        ...state,
        error: false,
        success: false,
        amount: 0,
        code: payload
      }

    case t.SET_PROMO_ERROR:
      return {
        ...state,
        error: payload.error,
        success: false,
        amount: 0,
        code: ''
      }

    /**
     * This action is fired when we recieve a response from the POST request letting the ui know we are
     * done loading
     */
    case t.APPLY_PROMO_RECEIVE:
      return {
        ...state,
        pending: false,
        error: payload.error,
        success: payload.success,
        amount: payload.amount
      }

    case t.TOGGLE_REMOVED_PROMO:
      return {
        ...state,
        removed: !state.removed
      }

    case t.RESET_PROMO:
      return defaultState

    default:
      return state
  }
}

export default promoReducer
