export default {
  CLOSE_SEARCH_DRAWER: 'CLOSE_SEARCH_DRAWER',
  REQUEST_FEATURED_PRODUCTS_ERROR: 'REQUEST_FEATURED_PRODUCTS_ERROR',
  REQUEST_FEATURED_PRODUCTS_LOADING: 'REQUEST_FEATURED_PRODUCTS_LOADING',
  SET_FEATURED_PRODUCTS: 'SET_FEATURED_PRODUCTS',
  SET_FUSE_GROUP_SEARCH: 'SET_FUSE_GROUP_SEARCH',
  SET_FUSE_PRODUCT_SEARCH: 'SET_FUSE_PRODUCT_SEARCH',
  SET_FUSE_BRAND_SEARCH: 'SET_FUSE_BRAND_SEARCH',
  OPEN_SEARCH_DRAWER: 'OPEN_SEARCH_DRAWER'
}
