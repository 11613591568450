/**
 * Reducers/store to maintain all loading states
 */

import t from './actionTypes'

export const defaultState = {
  address: false,
  cancelOrder: false,
  creatingOrder: false,
  creatingSupportTicket: false,
  deliveries: false,
  eta: false,
  group: false,
  helpArticle: false,
  login: false,
  mmidImage: false,
  menuProducts: false,
  phoneConfirm: false,
  phoneInput: false,
  product: false,
  products: false,
  productType: false,
  quote: false,
  resetPassword: false,
  signup: false,
  stateIDImage: false,
  stateIDUpload: false,
  taxFees: false,
  userProfile: false,
  userStatus: false,
  personaLoading: false
}

const loading = (state = defaultState, action) => {
  switch (action.type) {
    case t.ADDRESS_LOADING:
      return {
        ...state,
        address: true
      }

    case t.ADDRESS_LOADED:
      return {
        ...state,
        address: false
      }

    case t.CANCEL_ORDER_LOADING:
      return {
        ...state,
        cancelOrder: true
      }

    case t.CANCEL_ORDER_LOADED:
      return {
        ...state,
        cancelOrder: false
      }

    case t.CART_LOADING:
      return {
        ...state,
        cart: true
      }

    case t.CART_LOADED:
      return {
        ...state,
        cart: false
      }

    case t.CREATING_SUPPORT_TICKET_LOADING:
      return {
        ...state,
        creatingSupportTicket: true
      }

    case t.CREATING_SUPPORT_TICKET_LOADED:
      return {
        ...state,
        creatingSupportTicket: false
      }

    case t.DELIVERIES_LOADING:
      return {
        ...state,
        deliveries: true
      }

    case t.DELIVERIES_LOADED:
      return {
        ...state,
        deliveries: false
      }

    case t.ETA_LOADING:
      return {
        ...state,
        eta: true
      }

    case t.ETA_LOADED:
      return {
        ...state,
        eta: false
      }

    case t.GROUP_LOADING:
      return {
        ...state,
        group: true
      }

    case t.GROUP_LOADED:
      return {
        ...state,
        group: false
      }

    case t.HELP_CENTER_ARTICLE_LOADING:
      return {
        ...state,
        helpArticle: true
      }

    case t.HELP_CENTER_ARTICLE_LOADED:
      return {
        ...state,
        helpArticle: false
      }

    case t.LOGIN_LOADING:
      return {
        ...state,
        login: true
      }

    case t.LOGIN_LOADED:
      return {
        ...state,
        login: false
      }

    case t.MENU_PRODUCTS_LOADING:
      return {
        ...state,
        menuProducts: true
      }

    case t.MENU_PRODUCTS_LOADED:
      return {
        ...state,
        menuProducts: false
      }

    case t.MMID_IMAGE_LOADING:
      return {
        ...state,
        mmidImage: true
      }

    case t.MMID_IMAGE_LOADED:
      return {
        ...state,
        mmidImage: false
      }

    case t.ORDER_CREATE_LOADING:
      return {
        ...state,
        creatingOrder: true
      }

    case t.ORDER_CREATE_LOADED:
      return {
        ...state,
        creatingOrder: false
      }

    case t.PHONE_CONFIRM_LOADING:
      return {
        ...state,
        phoneConfirm: true
      }

    case t.PHONE_CONFIRM_LOADED:
      return {
        ...state,
        phoneConfirm: false
      }

    case t.PHONE_INPUT_LOADING:
      return {
        ...state,
        phoneInput: true
      }

    case t.PHONE_INPUT_LOADED:
      return {
        ...state,
        phoneInput: false
      }

    // used on product pages, e.g. /products/123
    case t.PRODUCT_LOADING:
      return {
        ...state,
        product: true
      }

    // used on product pages, e.g. /products/123
    case t.PRODUCT_LOADED:
      return {
        ...state,
        product: false
      }

    // used for products in cart in /menu and in /checkout
    case t.PRODUCTS_LOADING:
      return {
        ...state,
        products: true
      }

    // used for products in cart in /menu and in /checkout
    case t.PRODUCTS_LOADED:
      return {
        ...state,
        products: false
      }

    case t.QUOTE_LOADING:
      return {
        ...state,
        quote: true
      }

    case t.QUOTE_LOADED:
      return {
        ...state,
        quote: false
      }

    case t.RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPassword: true
      }

    case t.RESET_PASSWORD_LOADED:
      return {
        ...state,
        resetPassword: false
      }

    case t.SIGNUP_LOADING:
      return {
        ...state,
        signup: true
      }

    case t.SIGNUP_LOADED:
      return {
        ...state,
        signup: false
      }

    case t.STATE_ID_IMAGE_LOADING:
      return {
        ...state,
        stateIDImage: true
      }

    case t.STATE_ID_IMAGE_LOADED:
      return {
        ...state,
        stateIDImage: false
      }

    case t.STATE_ID_UPLOADED:
      return {
        ...state,
        stateIDUpload: false
      }

    case t.STATE_ID_UPLOADING:
      return {
        ...state,
        stateIDUpload: true
      }

    case t.TAX_FEES_LOADING:
      return {
        ...state,
        taxFees: true
      }

    case t.TAX_FEES_LOADED:
      return {
        ...state,
        taxFees: false
      }

    case t.USER_PROFILE_LOADING:
      return {
        ...state,
        userProfile: true
      }

    case t.USER_PROFILE_LOADED:
      return {
        ...state,
        userProfile: false
      }

    case t.USER_STATUS_LOADING:
      return {
        ...state,
        userStatus: true
      }

    case t.USER_STATUS_LOADED:
      return {
        ...state,
        userStatus: false
      }

    case t.PERSONA_LOADING:
      return {
        ...state,
        personaLoading: true
      }
    case t.PERSONA_LOADED:
      return {
        ...state,
        personaLoading: false
      }
    default:
      return state
  }
}

export default loading
