import t from './actionTypes'

export const defaultState = {
  cartId: '',
  coordinates: '',
  hasVerifiedAge: false,
  loggedin: false,
  referrer: '',
  token: '',
  userId: '',
  placeId: ''
}
export type CookieNames = keyof typeof defaultState
export type CookieValues = typeof defaultState[CookieNames]

const cookieReducer = (
  state = defaultState,
  action: { type?: string; cookies?: Partial<typeof defaultState> } = {}
) => {
  switch (action.type) {
    case t.COOKIE_REHYDRATE:
      return {
        ...state,
        ...action.cookies
      }

    case t.SET_COOKIE:
      return {
        ...state,
        ...action.cookies
      }

    default:
      return state
  }
}

export default cookieReducer
