/*
  This code is more or less a direct port of https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/preferences.ts
*/
import { EventEmitter } from 'events'

import topDomain from '@segment/top-domain'
import clientCookies from 'js-cookie'
import { GetServerSidePropsContext } from 'next'
import requestCookies from 'next-cookies'

import { isBrowserContext } from '@helpers/constants'

const COOKIE_KEY = 'tracking-preferences'
const COOKIE_EXPIRES = 365

const getPreferencesFromCtx = (ctx: GetServerSidePropsContext) => {
  const cookies = requestCookies(ctx)
  return cookies[COOKIE_KEY]
}

function getPreferences(ctx?: GetServerSidePropsContext) {
  // ctx added so we can parse from a `getServerSideProps` ctx object if needed
  const preferences = ctx ? getPreferencesFromCtx(ctx) : clientCookies.getJSON(COOKIE_KEY)
  if (!preferences || !preferences.destinations || !preferences.custom) {
    return {
      destinationPreferences: {},
      customPreferences: {}
    }
  }
  const prefs = {
    destinationPreferences: preferences.destinations,
    customPreferences: preferences.custom
  }
  return prefs
}

const emitter = new EventEmitter()
function onPreferencesSaved(listener) {
  emitter.on('preferencesSaved', listener)
  return () => emitter.off('preferencesSaved', listener)
}

function savePreferences({ destinationPreferences, customPreferences, cookieDomain }) {
  if (!isBrowserContext) return

  const wd = window || { location: {} }

  // It's not documented why this data is sent in an identify call
  if ('analytics' in wd) {
    wd.analytics.identify({
      destinationTrackingPreferences: destinationPreferences,
      customTrackingPreferences: customPreferences
    })
  }

  const domain = cookieDomain || topDomain(window.location.href)
  const value = {
    version: 1,
    destinations: destinationPreferences,
    custom: customPreferences
  }

  clientCookies.set(COOKIE_KEY, value, {
    expires: COOKIE_EXPIRES,
    domain
  })

  emitter.emit('preferencesSaved', {
    destinationPreferences,
    customPreferences
  })
}

export { getPreferences, savePreferences, onPreferencesSaved }
