import { getStoredState, REHYDRATE } from 'redux-persist'

export default function crossBrowserListener(store, persistConfig) {
  return async function() {

    // get stored state object to use as payload
    const state = await getStoredState(persistConfig)

    // REHYDRATE action dispatched to update the redux store
    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state
    })
  }
}
