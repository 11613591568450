import { Product } from '@bff/models/og/menu'

import t from './actionTypes'

export const defaultState = {
  collection: <Record<number, Product>>{}, // keys are product.id, values are entire product object
  map: <Record<string, number>>{} // keys are product.catalogItemId, values are product.id
}

const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PRODUCTS:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.payload
        }
      }

    case t.SET_PRODUCT_ID_MAP:
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload
        }
      }

    default:
      return state
  }
}

export default productsReducer
