// Colors Types

import { Theme } from '@emotion/react'

interface ColorGroup {
  default: string
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface Colors {
  salmon: ColorGroup
  eazeBlue: ColorGroup
  purpleHaze: ColorGroup
  orange: ColorGroup
  subYellow: ColorGroup
  slimeGreen: ColorGroup
  teal: ColorGroup
  vibrantBlue: ColorGroup
  red: ColorGroup
  black: ColorGroup
  cbd: string
  hybrid: string
  white: string
  accessory: string
  beige: string
  indica: string
  sativa: string
  brightYellow: string
  [key: string]: ColorGroup | string
}

// Colors

export const Colors: Colors = {
  salmon: {
    default: '#FC8579',
    50: '#FEE2DF',
    100: '#FED8D5',
    200: '#FECBC6',
    300: '#FDBAB4',
    400: '#FDA39A',
    500: '#FC8579',
    600: '#E86155',
    700: '#D94134',
    800: '#B9372C',
    900: '#98332B'
  },
  eazeBlue: {
    default: '#00A6F0',
    50: '#BFEBFF',
    100: '#AAE4FF',
    200: '#8DDBFF',
    300: '#67CFFF',
    400: '#35BFFF',
    500: '#00A6F0',
    600: '#148FB8',
    700: '#187E95',
    800: '#1A6E7A',
    900: '#1A5F63'
  },
  purpleHaze: {
    default: '#7F4ACB',
    50: '#E1D4F3',
    100: '#D7C6EF',
    200: '#C9B3E9',
    300: '#B799E2',
    400: '#A077D8',
    500: '#7F4ACB',
    600: '#6442AA',
    700: '#4F3C8C',
    800: '#403774',
    900: '#343160'
  },
  orange: {
    default: '#FF9D25',
    50: '#FFE8CB',
    100: '#FFE0BA',
    200: '#FFD6A3',
    300: '#FFC885',
    400: '#FFB65C',
    500: '#FF9D25',
    600: '#E08419',
    700: '#B66E1E',
    800: '#945C20',
    900: '#794D20'
  },
  subYellow: {
    default: '#FFE400',
    50: '#FFF9C2',
    100: '#FFF7AE',
    200: '#FFF493',
    300: '#FFF170',
    400: '#FFEC40',
    500: '#FFE400',
    600: '#C3AF16',
    700: '#9E8C1A',
    800: '#81721C',
    900: '#695C1C'
  },
  slimeGreen: {
    default: '#60E567',
    50: '#D9F9DB',
    100: '#CDF7CF',
    200: '#BCF4BF',
    300: '#A5F0A9',
    400: '#88EB8D',
    500: '#60E567',
    600: '#47CD48',
    700: '#3CB239',
    800: '#3C9235',
    900: '#397831'
  },
  teal: {
    default: '#2FC5BC',
    50: '#CCF3F0',
    100: '#BBEFEB',
    200: '#A4E9E5',
    300: '#86E2DC',
    400: '#5ED9D0',
    500: '#2FC5BC',
    600: '#359B8E',
    700: '#317F71',
    800: '#2D695A',
    900: '#295649'
  },
  vibrantBlue: {
    default: '#181AC0',
    50: '#C1C2F7',
    100: '#ACAEF5',
    200: '#9192F1',
    300: '#6C6EED',
    400: '#3B3EE7',
    500: '#181AC0',
    600: '#232E95',
    700: '#22327A',
    800: '#213264',
    900: '#1F3052'
  },
  red: {
    default: '#FF0038',
    50: '#FFC2D0',
    100: '#FFAEC0',
    200: '#FF93AB',
    300: '#FF708F',
    400: '#FF4069',
    500: '#FF0038',
    600: '#C3164F',
    700: '#9E1A55',
    800: '#811C54',
    900: '#691C4F'
  },
  black: {
    default: '#000000',
    50: '#E5E5E5',
    100: '#CCCCCC',
    200: '#B3B3B3',
    300: '#999999',
    400: '#808080',
    500: '#666666',
    600: '#4C4C4C',
    700: '#333333',
    800: '#1A1A1A',
    900: '#000000'
  },
  cbd: '#9360A8',
  hybrid: '#42BC97',
  white: '#FFFFFF',
  accessory: '#5C515A',
  beige: '#FFF8F5',
  indica: '#126CAB',
  sativa: '#FF9D25',
  brightYellow: '#F9FF00'
}

// Themes

// We use lightTheme as the default theme interface.
// Access values in project via import and dot notation:
// import { lightTheme } from 'microcomponents/theme'
// lightTheme.colors.salmon, ${lightTheme.colors.eazeBlue}, etc.

export const lightTheme: Theme = {
  colors: {
    background: Colors.white,
    backgroundInverse: Colors.black.default,
    backgroundDisabled: Colors.black[50],
    text: Colors.black.default,
    textInverse: Colors.white,
    textDisabled: Colors.black[300],
    link: Colors.purpleHaze.default,
    linkAction: Colors.purpleHaze[200],
    button: Colors.subYellow.default,
    border: Colors.black.default,
    borderInput: Colors.black[300],
    salmon: Colors.salmon.default,
    eazeBlue: Colors.eazeBlue.default,
    purpleHaze: Colors.purpleHaze.default,
    orange: Colors.orange.default,
    subYellow: Colors.subYellow.default,
    subYellowDisabled: Colors.subYellow[50],
    slimeGreen: Colors.slimeGreen.default,
    teal: Colors.teal.default,
    vibrantBlue: Colors.vibrantBlue.default,
    red: Colors.red.default,
    black: Colors.black.default,
    cbd: Colors.cbd,
    danger: Colors.red[400],
    white: Colors.white,
    hybrid: Colors.hybrid,
    accessory: Colors.accessory,
    beige: Colors.beige,
    grey: Colors.black[500],
    lightGrey: Colors.black[400],
    lighterGrey: Colors.black[200],
    indica: Colors.indica,
    sativa: Colors.sativa,
    brightYellow: Colors.brightYellow,
    bannerError: Colors.red[400],
    bannerWarning: Colors.orange[400],
    bannerSuccess: Colors.slimeGreen[300],
    bannerInfo: Colors.black.default
  },
  spacing: {
    default: 16,
    xs: 4,
    smaller: 8,
    small: 10,
    medium: 20,
    large: 30,
    larger: 40,
    xl: 50,
    xxl: 80
  },
  border: {
    borderRadius: {
      default: 0,
      round: 5.6
    }
  },
  typography: {
    font: {
      sim: `'Suisse Intl Mono Regular', monospace`,
      simBold: `'Suisse Intl Mono Bold', monospace`,
      simMono: `'Suisse Intl Mono', monospace`,
      si: 'Suisse Intl Book',
      siBold: 'Suisse Intl Bold',
      np: 'Neue Plak Extended Regular',
      npBold: 'Neue Plak Extended Bold'
    },
    weight: {
      regular: 400,
      bold: 700
    },
    size: {
      desktop: {
        heading: 52,
        largeTitle: 48,
        title1: 40,
        title2: 32,
        title3: 24,
        title4: 20,
        price: 22,
        body1: 20,
        body2: 16,
        body3: 14,
        body4: 12,
        caption: 12,
        badge: 11,
        tiny: 10
      },
      mobile: {
        heading: 32,
        largeTitle: 26,
        title1: 24,
        title2: 18,
        title3: 14,
        title4: 12,
        price: 18,
        body1: 16,
        body2: 14,
        body3: 12,
        body4: 10,
        caption: 10,
        badge: 10,
        tiny: 10
      }
    }
  }
}

export const darkTheme: Theme = {
  ...lightTheme,
  colors: {
    background: Colors.black.default,
    backgroundInverse: Colors.white,
    backgroundDisabled: Colors.black[50],
    text: Colors.white,
    textInverse: Colors.black.default,
    textDisabled: Colors.black[300],
    link: Colors.purpleHaze.default,
    linkAction: Colors.purpleHaze[200],
    button: Colors.subYellow[300],
    border: Colors.black.default,
    borderInput: Colors.black[300],
    salmon: Colors.salmon[300],
    eazeBlue: Colors.eazeBlue[300],
    purpleHaze: Colors.purpleHaze[300],
    orange: Colors.orange[300],
    subYellow: Colors.subYellow[300],
    subYellowDisabled: Colors.subYellow[50],
    slimeGreen: Colors.slimeGreen[300],
    teal: Colors.teal[300],
    vibrantBlue: Colors.vibrantBlue[300],
    red: Colors.red[300],
    black: Colors.black.default,
    cbd: Colors.cbd,
    danger: Colors.red[400],
    white: Colors.white,
    hybrid: Colors.hybrid,
    accessory: Colors.accessory,
    beige: Colors.beige,
    grey: Colors.black[500],
    lightGrey: Colors.black[400],
    lighterGrey: Colors.black[200],
    indica: Colors.indica,
    sativa: Colors.sativa,
    brightYellow: Colors.brightYellow,
    bannerError: Colors.red[400],
    bannerWarning: Colors.orange[400],
    bannerSuccess: Colors.slimeGreen[300],
    bannerInfo: Colors.black.default
  }
}

// Breakpoints Types

interface BreakpointSize {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
  xxl: number
}

interface Breakpoint {
  min: BreakpointSize
  max: BreakpointSize
}

// Breakpoints

// breakpoint.max.* used for non-integer pixel densities
// (eg high-dpi/retina displays or as a result of zooming/scaling).
// A fix for this would be adding 0.02px to reduce the viewport
// falling between the "cracks" to address the Safari/iOS bug:
// https://bugs.webkit.org/show_bug.cgi?id=178261
// Ref: https://www.w3.org/TR/mediaqueries-4/#mq-min-max

export const breakpoint: Breakpoint = {
  min: {
    xs: 376,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440
  },
  max: {
    xs: 375.98,
    sm: 575.98,
    md: 767.98,
    lg: 991.98,
    xl: 1199.98,
    xxl: 1439.98
  }
}
