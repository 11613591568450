export default {
  RESET_PROMO: 'RESET_PROMO',
  APPLY_PROMO_RECEIVE: 'APPLY_PROMO_RECEIVE',
  APPLY_PROMO_SEND: 'APPLY_PROMO_SEND',
  RESET_PROMO_MODAL: 'RESET_PROMO_MODAL',
  SET_PROMO: 'SET_PROMO',
  SET_PROMO_ERROR: 'SET_PROMO_ERROR',
  TOGGLE_REMOVED_PROMO: 'TOGGLE_REMOVED_PROMO',
  OPEN_APPLY_PROMO: 'OPEN_APPLY_PROMO'
}
