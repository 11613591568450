export default {
  FETCH_PAYMENT_METHODS_LOADING: 'FETCH_PAYMENT_METHODS_LOADING',
  FETCH_PAYMENT_METHODS_LOADED: 'FETCH_PAYMENT_METHODS_LOADED',
  SET_ACH: 'SET_ACH',
  SET_ACH_ADDRESS: 'SET_ACH_ADDRESS',
  SET_ACH_URL: 'SET_ACH_URL',
  SET_CHARGE_DESCRIPTOR: 'SET_CHARGE_DESCRIPTOR',
  SWITCH_PAYMENT_METHOD: 'SWITCH_PAYMENT_METHOD',
  SET_PAYMENT_PROVIDERS: 'SET_PAYMENT_PROVIDERS',
  SET_DRIVER_TIP: 'SET_DRIVER_TIP'
}
