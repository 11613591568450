import t from './actionTypes'

export const defaultState = {
  expiredIdExperimentFlag: ''
}

const experiments = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_EXPIRED_ID_EXPERIMENT:
      return {
        ...state,
        expiredIdExperimentFlag: action.flag
      }
    default:
      return state
  }
}

export default experiments
