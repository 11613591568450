import t from './actionTypes'

type ArticleShape = {
  body: string
  title: string
}

export const defaultState = {
  article: <ArticleShape>{},
  previousOrders: <number[]>[] // array of orderIds to show order history
}

const helpCenter = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_HELP_CENTER_ARTICLE:
      return {
        ...state,
        article: action.article
      }

    case t.SET_PREVIOUS_ORDERS:
      return {
        ...state,
        previousOrders: action.previousOrders
      }

    default:
      return state
  }
}

export default helpCenter
