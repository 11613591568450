import tokenStore from '@eaze/eaze-api-client/es/token-store'
import { initSplitSdk } from '@splitsoftware/splitio-redux'
import window from 'global/window'
import isEmptyObject from 'is-empty-object'
import Cookies from 'js-cookie'
import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { createTransform, persistReducer, persistStore, REHYDRATE } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2' // merge incoming state with initial state two levels deep.
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import thunk from 'redux-thunk'

import { CLUSTER_NAME, splitConfigWithKey } from '@helpers/constants'

import authActionTypes from './redux/auth/actionTypes'
import { setCookieValue } from './redux/cookies/actions'
import cookiesTypes from './redux/cookies/actionTypes'
import allReducers from './redux/reducers'

// Define the noop storage as a fallback for the server-side
const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem(_key) {
      return Promise.resolve()
    }
  }
}

// Conditionally use createWebStorage or noop storage based on the environment
const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage()

const appReducer = combineReducers(allReducers)

const rootReducer = (state, action) => {
  if (action.type === REHYDRATE) {
    if (action.payload && action.payload.user) {
      tokenStore.set(action.payload.user.xAuthToken)
    }
  }

  if (action.type === cookiesTypes.COOKIE_REHYDRATE) {
    if (action.cookies?.token) {
      tokenStore.set(action.cookies.token)
    }
  }

  // If the user logs out, we want delete all state.
  if (action.type === authActionTypes.LOGOUT) {
    storage.removeItem('persist:eazedotcom')

    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const bindMiddleware = (middleware) => {
  const isProd = CLUSTER_NAME === 'production'

  if (isProd) {
    return composeWithDevTools(applyMiddleware(...middleware))
  } else {
    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
    return composeEnhancers(applyMiddleware(...middleware))
  }
}

// transform inbound/outbound-going state
const cartTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    const newState = {}
    // we do not want cart messages to persist in the local storage layer
    const cartKeys = Object.keys(inboundState)
    cartKeys.forEach(function (cartKey) {
      // we won't be able to delete a message off of the lastMenuSlug key
      if (cartKey !== 'lastMenuSlug') {
        const cartCopy = { ...inboundState[cartKey] }
        delete cartCopy.message
        newState[cartKey] = cartCopy
      } else {
        newState[cartKey] = inboundState[cartKey]
      }
    })
    return newState
  },
  // transform state being rehydrated. only migrate when reading from localStorage
  (outboundState, key) => outboundState,
  // define which reducers this transform gets called for.
  { whitelist: ['cart'] }
)

const cookieTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => inboundState,
  // transform state being rehydrated.
  (outboundState, key) => {
    // sets cookie if values exist, reading from localStorage
    if (key === 'user') {
      outboundState.userId && setCookieValue('userId', outboundState.userId)
      outboundState.xAuthToken && setCookieValue('token', outboundState.xAuthToken)
    } else if (key === 'location') {
      if (!isEmptyObject(outboundState.activeLocation)) {
        setCookieValue('placeId', outboundState.activeLocation.id)
        if (!isEmptyObject(outboundState.activeLocation.coordinates)) {
          setCookieValue('coordinates', outboundState.activeLocation.coordinates)
        }
      }
    }
    return outboundState
  },
  // define which reducers this transform gets called for.
  { whitelist: ['user', 'location'] }
)

export const persistConfig = {
  key: 'eazedotcom',
  transforms: [cartTransform, cookieTransform],
  storage: storage,
  whitelist: ['cart', 'location', 'announcements', 'profile', 'user'],
  stateReconciler: autoMergeLevel2
}

// https://github.com/fazlulkarimweb/with-next-redux-wrapper-redux-persist/blob/master/store/store.js
const makeStore = () => {
  const isServer = typeof window === 'undefined'
  if (isServer) {
    // on the server we create a new store with default
    return createStore(rootReducer, bindMiddleware([thunk]))
  } else {
    // on the client we combine persisted store with default
    const persistedReducer = persistReducer(persistConfig, rootReducer)

    // we then create a new store
    const store = createStore(persistedReducer, bindMiddleware([thunk]))

    // and persist the store again
    store.__persistor = persistStore(store)

    const splitKey = Cookies.getJSON('eaze_userId') || Cookies.getJSON('ajs_anonymous_id')

    store.dispatch(initSplitSdk({ config: splitConfigWithKey(splitKey) }))

    return store
  }
}

export function createStoreWithState(initialState, middleware = []) {
  return createStore(rootReducer, initialState, bindMiddleware([thunk, ...middleware]))
}

export const reduxWrapper = createWrapper(makeStore)
