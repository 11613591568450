import t from './actionTypes'

/*
A card shape:
 {
    externalCardId: "c4b435d6-b2b2-47h9-985b-632383e53b3d"
    last4Digits: "1234"
    cvc: "" // They never come back with CVC, we fill that in
    userId: 245350
    internalCardId: null
    isEnabled: true
    expiryMonth: 3
    expiryYear: 2020
    brand: "Visa"
 }
*/

export const defaultState = {
  ach: null,
  activePaymentId: null, // Can be `0` for cash, or an `externalCardId`
  // or 2 for card on delivery or 3 for debit (pin required) on delivery
  chargeDescriptor: '',
  driverTip: 0
}

export default function payments(state = defaultState, { type, payload, error, meta }) {
  switch (type) {
    case t.SET_ACH:
      return {
        ...state,
        ach: payload
      }

    case t.SWITCH_PAYMENT_METHOD:
      return {
        ...state,
        activePaymentId: payload
      }

    case t.SET_PAYMENT_PROVIDERS:
      return {
        ...state,
        paymentProviders: {
          ...state.paymentProviders,
          [payload.paymentMethod]: payload
        }
      }
    case t.SET_DRIVER_TIP:
      return {
        ...state,
        driverTip: payload
      }

    default:
      return state
  }
}
