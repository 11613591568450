/**
 * Action types for auth
 */
export default {
  ATTEMPT_EMAIL_CHANGE: 'ATTEMPT_EMAIL_CHANGE',
  ATTEMPT_ID_CHANGE: 'ATTEMPT_ID_CHANGE',
  ATTEMPT_PHONE_CHANGE: 'ATTEMPT_PHONE_CHANGE',
  LOGOUT: 'LOGOUT',
  PHONE_CONFIRM_ERROR: 'PHONE_CONFIRM_ERROR',
  RECEIVE_LOGIN_ERROR: 'RECEIVE_LOGIN_ERROR',
  RECEIVE_LOGIN_SUCCESS: 'RECEIVE_LOGIN_SUCCESS',
  RECEIVE_SIGNUP_ERROR: 'RECEIVE_SIGNUP_ERROR',
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  REQUEST_SIGNUP: 'REQUEST_SIGNUP',
  RESET_ERROR: 'RESET_ERROR',
  RESET_EXISTING_SIGNUP_IDENTIFIER: 'RESET_EXISTING_SIGNUP_IDENTIFIER',
  RESET_INVALID_LOGIN_ATTEMPT: 'RESET_INVALID_LOGIN_ATTEMPT',
  RESET_INVALID_PASSWORD_RECOVERY_ATTEMPT: 'RESET_INVALID_PASSWORD_RECOVERY_ATTEMPT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_RECOVERY_IDENTIFIER: 'RESET_PASSWORD_RECOVERY_IDENTIFIER',
  RESET_PROFILE_CHANGE: 'RESET_PROFILE_CHANGE',
  SET_INVALID_LOGIN_ATTEMPT: 'SET_INVALID_LOGIN_ATTEMPT',
  SET_INVALID_PASSWORD_RECOVERY_ATTEMPT: 'SET_INVALID_PASSWORD_RECOVERY_ATTEMPT',
  SET_PASSWORD_RECOVERY_IDENTIFIER: 'SET_PASSWORD_RECOVERY_IDENTIFIER'
}
