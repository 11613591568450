import { ISplitState } from '@splitsoftware/splitio-redux'
import { DeepPartial } from 'redux'

import addressModal, { defaultState as addressModalState } from './addressModal/reducer'
import alert, { defaultState as alertState } from './alert/reducer'
import announcements, { defaultState as announcementsState } from './announcements/reducer'
import app, { defaultState as appState } from './app/reducer'
import auth, { defaultState as authState } from './auth/reducer'
import blog, { defaultState as blogState } from './blog/reducer'
import cart, { defaultState as cartState } from './cart/reducer'
import checkout, { defaultState as checkoutState } from './checkout/reducer'
import cookies, { defaultState as cookiesState } from './cookies/reducer'
import deliveries, { defaultState as deliveriesState } from './deliveries/reducer'
import eta, { defaultState as etaState } from './eta/reducer'
import experiments, { defaultState as experimentsState } from './experiments/reducer'
import helpCenter, { defaultState as helpCenterState } from './help-center/reducer'
import loading, { defaultState as loadingState } from './loading/reducer'
import location, { defaultState as locationState } from './location/reducer'
import menu, { defaultState as menuState } from './menu/reducer'
import modal, { defaultState as modalState } from './modal/reducer'
import orderStatus, { defaultState as orderStatusState } from './order-status/reducer'
import orders, { defaultState as ordersState } from './order/reducer'
import payments, { defaultState as paymentsState } from './payments/reducer'
import products, { defaultState as productsState } from './products/reducer'
import profile, { defaultState as profileState } from './profile/reducer'
import promo, { defaultState as promoState } from './promo/reducer'
import quote, { defaultState as quoteState } from './quote/reducer'
import search, { defaultState as searchState } from './search/reducer'
import splitio, { defaultState as splitioState } from './splitio/reducer'
import user, { defaultState as userState } from './user/reducer'
import verification, { defaultState as verificationState } from './verification/reducer'

export const defaultStore = {
  addressModal: addressModalState,
  alert: alertState,
  announcements: announcementsState,
  app: appState,
  auth: authState,
  blog: blogState,
  cart: cartState,
  checkout: checkoutState,
  cookies: cookiesState,
  deliveries: deliveriesState,
  eta: etaState,
  experiments: experimentsState,
  helpCenter: helpCenterState,
  loading: loadingState,
  location: locationState,
  menu: menuState,
  products: productsState,
  promo: promoState,
  modal: modalState,
  orders: ordersState,
  orderStatus: orderStatusState,
  payments: paymentsState,
  profile: profileState,
  quote: quoteState,
  search: searchState,
  splitio: splitioState,
  user: userState,
  verification: verificationState
}

export type RootState = DeepPartial<typeof defaultStore>

export default {
  addressModal,
  alert,
  announcements,
  app,
  auth,
  blog,
  cart,
  checkout,
  cookies,
  deliveries,
  eta,
  experiments,
  helpCenter,
  loading,
  location,
  menu,
  products,
  promo,
  modal,
  orders,
  orderStatus,
  payments,
  profile,
  quote,
  search,
  splitio,
  user,
  verification
}
