export default {
  SET_PRIMARY_ARTICLE: 'SET_PRIMARY_ARTICLE',
  SET_SECONDARY_ARTICLE: 'SET_SECONDARY_ARTICLE',
  SET_TERTIARY_ARTICLES: 'SET_TERTIARY_ARTICLES',
  SET_CURRENT_ARTICLE: 'SET_CURRENT_ARTICLE',
  SET_ARTICLE_GROUP_ID: 'SET_ARTICLE_GROUP_ID',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  LOADING_POSTS: 'LOADING_POSTS',
  LOADED_POSTS: 'LOADED_POSTS',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  PUSH_NEW_TERTIARY_ARTICLES: 'PUSH_NEW_TERTIARY_ARTICLES'
}
