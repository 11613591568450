/**
 * Action types for order
 */
export default {
  RATE_ORDER: 'RATE_ORDER',
  RECEIVE_ORDER: 'RECEIVE_ORDER',
  REQUEST_ORDER: 'REQUEST_ORDER',
  RESET_ORDER: 'RESET_ORDER',
  SELECT_CANCEL_REASON: 'SELECT_CANCEL_REASON',
  SET_CANCEL_TEXT: 'SET_CANCEL_TEXT',
  SET_CHARGE_DESCRIPTOR: 'SET_CHARGE_DESCRIPTOR',
  SET_FEEDBACK_TEXT: 'SET_FEEDBACK_TEXT',
  SET_PAST_ORDERS: 'SET_PAST_ORDERS',
  SUBMIT_ORDER_FEEDBACK: 'SUBMIT_ORDER_FEEDBACK',
  SUBMITTING_CANCEL: 'SUBMITTING_CANCEL',
  TOGGLE_CANCEL_MODAL: 'TOGGLE_CANCEL_MODAL',
  TOGGLE_CANCEL_REASONS: 'TOGGLE_CANCEL_REASONS',
  TOGGLE_SHOW_ORDER_DETAILS: 'TOGGLE_SHOW_ORDER_DETAILS',
  TRANSITION_TO_ASSIGNING_DRIVER: 'ASSIGN_TO_DRIVER',
  VIEW_ORDER: 'VIEW_ORDER'
}
