/**
 * Action types for the verification flow
 */
export default {
  RECEIVE_PHONE_CODE_ERROR: 'RECEIVE_PHONE_CODE_ERROR',
  RECEIVE_PHONE_CODE_RESENT: 'RECEIVE_PHONE_CODE_RESENT',
  RECEIVE_PHONE_CODE_SENT: 'RECEIVE_PHONE_CODE_SENT',
  RECEIVE_UPLOAD_IDENTIFICATION_ERROR: 'RECEIVE_UPLOAD_IDENTIFICATION_ERROR',
  RECEIVE_UPLOAD_IDENTIFICATION_SUCCESS: 'RECEIVE_UPLOAD_IDENTIFICATION_SUCCESS',
  RECEIVE_UPLOAD_RECOMMENDATION_ERROR: 'RECEIVE_UPLOAD_RECOMMENDATION_ERROR',
  RECEIVE_UPLOAD_RECOMMENDATION_SUCCESS: 'RECEIVE_UPLOAD_RECOMMENDATION_SUCCESS',
  REQUEST_UPLOAD_IDENTIFICATION: 'REQUEST_UPLOAD_IDENTIFICATION',
  REQUEST_UPLOAD_RECOMMENDATION: 'REQUEST_UPLOAD_RECOMMENDATION',
  RESET_RECEIVE_PHONE_CODE_RESENT: 'RESET_RECEIVE_PHONE_CODE_RESENT',
  RESET_PHONE_CODE_ERROR: 'RESET_PHONE_CODE_ERROR',
  SET_PAGE: 'SET_PAGE',
  SET_PHONENUMBER: 'SET_PHONENUMBER',
  SET_PERSONA_INQUIRY_ID: 'SET_PERSONA_INQUIRY_ID',
  SET_PERSONA_LOADING: 'SET_PERSONA_LOADING',
  SET_PERSONA_INQUIRY_STATUS: 'SET_PERSONA_INQUIRY_STATUS',
  SET_PERSONA_SESSION_TOKEN: 'SET_PERSONA_SESSION_TOKEN'
}
