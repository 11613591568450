import Segment from '@eaze/load-segment'
import window from 'global/window'

import { ADJUST_APP_TOKEN, CLUSTER_NAME, SEGMENT_KEY, isBrowserContext } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'
import { isInAppView } from '@helpers/is-mobile'

import { getPreferences } from './preferences'

export function initAdjust() {
  if (!isInAppView) {
    // console.log('Adjust is not available in this context')
    return
  }

  if (window.Adjust) {
    window.Adjust.initSdk({
      appToken: ADJUST_APP_TOKEN,
      environment: CLUSTER_NAME === 'production' ? 'production' : 'sandbox',
      logLevel: 'error'
    })
  } else {
    errorHandler(
      new Error(
        'Adjust script loaded but something went wrong with initialization. This may be a problem with the Adjust SDK or the environment it is running in.'
      )
    )
  }
}

function getSegmentClient() {
  const { destinationPreferences } = getPreferences()
  const hasUserDisabledSegment = destinationPreferences['Segment.io'] === false
  // We only need segment on the client
  if (isBrowserContext && !hasUserDisabledSegment) {
    return window.analytics ? window.analytics : Segment({ key: SEGMENT_KEY })
  } else {
    // no-op everything for server env or if user disabled segment
    return {
      identify: () => {},
      page: () => {},
      track: () => {},
      reset: () => {}
    }
  }
}

/*
Adjust allows you to create custom events under Settings > Events, which have custom tokens.
Adjust's API only accepts those tokens, not arbitrary event names.
Any unmapped events will not be sent to Adjust, since they require a mapped token.
*/
const segmentAdjustEventMap = {
  'Signup.Success': 'rmhoj9',
  'Menu.Product.Add': 'mjpzf9',
  'Search.Click': 'of38p6',
  'Checkout.Success': 'ylxtsd',
  'Checkout.Success.First': '17gvee'
}

const track = function (eventName, properties = {}, integrations = {}, callback) {
  // uncomment these console.infos if you want to see your analytics logging locally
  // console.info('analytics track', eventName, properties, integrations, callback)

  if (isBrowserContext) {
    properties.userAgent = navigator.userAgent
  }

  const segmentClient = getSegmentClient()
  segmentClient.track(eventName, properties, integrations, callback)

  if (isBrowserContext && segmentAdjustEventMap[eventName]) {
    const trackInfo = { eventToken: segmentAdjustEventMap[eventName] }
    const eventInfo = { detail: {} }

    if (eventName === 'Checkout.Success') {
      trackInfo.revenue = properties.chargeAmount
      trackInfo.currency = 'USD'
      eventInfo.detail.revenue = properties.chargeAmount
    }
    if (isInAppView) {
      // dispatch event to handle adjust in React Native app
      window.dispatchEvent(new CustomEvent(eventName, eventInfo))
    } else {
      // make adjust calls only on non-webview browser dotcom
      window.Adjust?.trackEvent(trackInfo)
    }
  }
}

const identify = function (userId) {
  userId = userId.toString()
  const traits = {}

  if (isBrowserContext) {
    traits.userAgent = navigator.userAgent
  }

  const segmentClient = getSegmentClient()
  segmentClient.identify(userId, traits)
}

const page = function (eventName = '', properties = {}) {
  // console.info('analytics page')
  const segmentClient = getSegmentClient()
  segmentClient.page(eventName, properties)
}

const resetUser = function () {
  const segmentClient = getSegmentClient()
  segmentClient.reset()
}

export { identify, page, resetUser, track }
