/**
 * Eta reducer
 */
import { DeliveryEstimate } from '@bff/models/og/delivery'

import t from './actionTypes'

interface EtaShape extends DeliveryEstimate {
  error: string
}

export const defaultState = <EtaShape>{}

const eta = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_ETA:
      return defaultState

    case t.RECEIVE_ETA:
      return {
        ...action.payload
      }

    case t.SET_ETA_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case t.CLEAR_ETA_ERROR:
      return {
        ...state,
        error: null
      }

    default:
      return state
  }
}

export default eta
