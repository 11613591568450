import { REHYDRATE } from 'redux-persist'

import t from './actionTypes'

export const getAnnouncementId = (id: number) => {
  return id + new Date().setHours(0, 0, 0, 0).valueOf()
}

export const defaultState = {
  dismissedAnnouncements: <number[]>[]
}

const announcements = (state = defaultState, action) => {
  switch (action.type) {
    case REHYDRATE: {
      if (!action.payload || !action.payload.announcements) {
        return defaultState
      }
      const announcements = action.payload.announcements.dismissedAnnouncements || []

      // NEXTJS: dismissedAnnouncements used to be an immutable list, we're going to check if it is from local storage and convert it
      if (announcements.toJS) {
        announcements.toJS()
      }

      return {
        ...state,
        dismissedAnnouncements: announcements
      }
    }

    case t.SET_DISMISSED_ANNOUNCEMENTS:
      // Announcements should be dismissed once per day, once a day passes, it should re-enable the next day
      return {
        ...state,
        dismissedAnnouncements: [...state.dismissedAnnouncements, getAnnouncementId(action.id)]
      }

    case t.REMOVE_DISMISSED_ANNOUNCEMENTS:
      return defaultState

    default:
      return state
  }
}

export default announcements
