/**
 * Utility to send errors to Sentry instead of throwing them.
 */

import * as Sentry from '@sentry/nextjs'

import { CLUSTER_NAME, SENTRY_ENABLED } from '@helpers/constants'

// these error messages are automatically ignored by sentry, we can use a mix of regular expressions and exact string matching
// the goal for sentry is to detect web app errors rather than business logic, network, or backend issues
// that said, we _should_ catch issues with how we handle those kinds errors (i.e. accessing properties on undefined objects)
// with those ideas in mind we want to ignore errors that we can't fix in the web app to cut down on noise
// imported in sentry configs
export const ignoreErrors: (string | RegExp)[] = [
  // group errors - group names change frequently and can have punctuation
  /Group not found (\w*\W*)*/,
  /Error: Group not found (\w*\W*)*/,
  /Error requesting group: Group not found (\w*\W*)*/,
  // promo related errors
  'ERROR_INVALID_PROMOCODE',
  'No items in your cart qualify for this code.',
  /Add \$\d* more to your cart to use this code\./, // matches errors with any dollar amount, i.e. 'Add $10 more to your cart to use this code.'
  'You have already used this promo code 1 times, which is the maximum allowed',
  'Error: This promo code can only be used on your first order',
  'Error: Please, add at least one non-promo product to the cart',
  // account status errors
  'Error: You must be at least 21 years old to use this application.',
  'Error: Current verification status [Pending verification] does not allow you to perform this action',
  'Error: Current verification status [Unable to Verify] does not allow you to perform this action',
  'Your password has expired, please reset it.',
  'Your account is suspended',
  'The Email address is not valid.',
  'Error: Please try a different password.',
  'The email address you entered is not in our system',
  'Collective agreement has not been accepted', // also known as the Terms of Service,
  'Incorrect password.',
  'This password was found in a list of commonly used passwords. Please try another password', // HIBP check in OG returns this if the password has been leaked previously
  'Error: Your password reset session is expired.',
  // Signup errors
  'Error: User with the same mobile phone is already registered',
  // payment/checkout errors
  'Error: Payment type not supported, please select a different payment type',
  /\$\d*\.\d\d order minimum/,
  /Error: \$\d*\.\d\d order minimum/,
  /Error: \$\d* order minimum/,
  'Order declined, our heuristics indicate a high possibility of fraud.',
  "Your order exceeds CA's legal purchase limit of 28.5 grams of flower per day. Includes: Flowers, Prerolls, Preroll Packs",
  "Your order exceeds MI's legal purchase limit of 71.25 grams of flower per day. Includes: Flowers, Prerolls, Preroll Packs",
  "Your order exceeds CA's legal purchase limit of 8 grams of cannabis extracts per day. Includes: Concentrates, Vaporizers, Drops, Edibles",
  /Non sufficient fund. minimum balance required \$\d*\.\d\d/, // ACH insufficient funds error
  'The billing address and postal code do not match.',
  'Declined invalid account, only checking bank account allowed', // ACH was update 9/3/2020 to reject savings accounts
  // maps/location errors
  'Google places error: ZERO_RESULTS', // response returned from google api when user enters an address that doesn't exist (includes incomplete addresses)
  'Error: Please, enter a full street address',
  // misc browser errors
  'ResizeObserver loop limit exceeded', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  'ResizeObserver loop completed with undelivered notifications.',
  "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
  "undefined is not an object (evaluating 'webkit.messageHandlers.loginsManagerMessageHandler.postMessage')",
  "undefined is not an object (evaluating 'webkit.messageHandlers.contextMenuMessageHandler.postMessage')",
  "null is not an object (evaluating 'document.head.querySelector(\"meta[name='supported-color-schemes']\").content')",
  "Can't find variable: getVideoAtPoint",
  "undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
  'NotSupportedError: Failed to get ServiceWorkerRegistration objects: The user denied permission to use Service Worker.',
  'AbortError: Failed to get ServiceWorkerRegistration objects: The Service Worker system has shutdown.',
  "__firefox__.searchQueryForField is not a function. (In '__firefox__.searchQueryForField()', '__firefox__.searchQueryForField' is undefined)",
  // network errors - possibly from user closing tab/browser or cancelling navigation while a request is pending
  'XHR Aborted',
  'Error: XMLHttpRequest timeout',
  'JSON.parse Error: Unexpected input at position:0', // these JSON parse errors are usually misleading as requests blocked by our WAF end up causing this error
  'JSON.parse: unexpected character at line 1 column 1 of the JSON data',
  'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data',
  "JSON Parse error: Unrecognized token '<'",
  'Unexpected token < in JSON at position 0',
  '[object XMLHttpRequestProgressEvent]',
  '[object ProgressEvent]',
  'listen EADDRINUSE: address already in use :::3000', // someone tried to run .com locally while they were already running .com locally
  // The CCPA profile archive request returns a 404 at first since no record has been created/requested yet.
  'Error: Request not found',
  // Why is this an error.... matches errors like "Eaze will open at 8:00 AM"
  /Eaze will open at \d:\d\d AM/,
  /Eaze will open at \d:\d\d PM/,
  /Eaze will open at \d:\d\d/,
  /Eaze will open at \d:\d\d/,
  /Error: Eaze will open at \d:\d\d AM/,
  /Error: Eaze will open at \d:\d\d PM/,
  /Error: Eaze will open at \d:\d\d/,
  /Error: Eaze will open at \d:\d\d/,
  "Blocked 'image' from 'www.ojrq.net'"
]

// allow errors from these domains _only_ imported in sentry configs
export const allowUrls: string[] = [
  'eaze.com',
  'www.eaze.com',
  'event-proxy.eaze.com',
  'xid.eaze.io',
  'xid.eaze.com',
  'localhost:3000', // local dev env
  'eaze.tech',
  'eazeup.com',
  'www.eazeup.com',
  'images.eazeup.com',
  'eaze.dev',
  'www.hybrid.eaze.dev',
  'www.staging.eaze.dev'
]

export function identifyUser(id: string): void {
  Sentry.getCurrentScope()?.setUser({ id })
}

export function captureAnonId(id: string): void {
  Sentry.setTag('anonymous_id', id)
}

const errorHandler = (error: Error, extras: Record<string, unknown> = {}): void => {
  // we don't want to capture errors unless its production because it costs money and we don't really use it
  if (CLUSTER_NAME === 'production' || SENTRY_ENABLED) {
    Sentry.captureException(error, (scope) => {
      scope.clear()
      scope.setExtras(extras)
      return scope
    })
  }
  else {
    console.error('Sentry Error: ', error, 'Sentry Extras: ', extras)
  }
}

export default errorHandler
