/**
 * Action types for alerts
 */

import { alertTypes } from 'redux/alert/config/types'

const alertTypesMap = {}

Object.keys(alertTypes).forEach((alert) => {
  const showKey = `SHOW_${alert}`
  const hideKey = `HIDE_${alert}`
  alertTypesMap[showKey] = showKey
  alertTypesMap[hideKey] = hideKey
})

alertTypesMap.SET_CURRENT_ACTION = 'SET_CURRENT_ACTION'
alertTypesMap.RESET_CURRENT_ACTION = 'RESET_CURRENT_ACTION'

export default alertTypesMap
