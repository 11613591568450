/**
 * Quotes reducer
 */
import { Quote, DepotPeakSettings, PriceInfo } from '@bff/models/og/quotes'

import t from './actionTypes'

export const defaultState: Quote & { lastQuoteTime: string } = {
  cartId: null,
  depotAdultUseLicense: '',
  depotId: null,
  dispensaryName: '',
  priceInfo: <PriceInfo>{},
  peakInfo: {
    current: <DepotPeakSettings>{}
  },
  products: [],
  lastQuoteTime: '',
  depotMedicalUseLicense: ''
}

const quote = (state = defaultState, { type, payload = {} }: { type: string; payload: Partial<Quote> }) => {
  switch (type) {
    // Used to reset invalid quote states
    case t.CLEAR_QUOTE:
      return defaultState

    case t.SET_QUOTE:
      return {
        ...state,
        ...payload
      }

    default:
      return state
  }
}

export default quote
