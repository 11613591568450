export default {
  SET_PLACE: 'SET_PLACE',
  REMOVE_PLACE: 'REMOVE_PLACE',
  PUSH_RECENT_ADDRESS: 'PUSH_RECENT_ADDRESS',
  REMOVE_PREVIOUS_ADDRESS: 'REMOVE_PREVIOUS_ADDRESS',
  SAVE_POTENTIAL_ADDRESS: 'SAVE_POTENTIAL_ADDRESS',
  SET_ADULT_USE_CITY_DEPOT: 'SET_ADULT_USE_CITY_DEPOT',
  IS_ADDRESS_EXACT: 'IS_ADDRESS_EXACT',
  SET_ACTIVE_DEPOT: 'SET_ACTIVE_DEPOT',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_ID_SCAN_REQUIRED: 'SET_ID_SCAN_REQUIRED',
  SET_RESIDENTIAL_ONLY: 'SET_RESIDENTIAL_ONLY',
  SET_US_ID_ONLY: 'SET_US_ID_ONLY',
  CLEAR_POTENTIAL_ADDRESS: 'CLEAR_POTENTIAL_ADDRESS'
}
