export default {
  CLEAR_CATALOG_ITEM: 'CLEAR_CATALOG_ITEM',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  MENU_NOT_ENABLED: 'MENU_NOT_ENABLED',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  REQUEST_PRODUCT: 'REQUEST_PRODUCT',
  REQUEST_PRODUCTS: 'REQUEST_PRODUCTS',
  SET_ANNOUNCEMENTS: 'SET_ANNOUNCEMENTS',
  SET_CATALOG_ITEM: 'SET_CATALOG_ITEM',
  SET_GROUPS: 'SET_GROUPS',
  SET_MENUS: 'SET_MENUS',
  SET_PRODUCT: 'SET_PRODUCT'
}
