import { REHYDRATE } from 'redux-persist'

import cookieActionTypes from 'redux/cookies/actionTypes'

import t from './actionTypes'

export const defaultState = {
  cookieHydrate: false,
  hydrated: false,
  versionMisMatch: false
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        hydrated: true
      }

    case cookieActionTypes.COOKIE_REHYDRATE:
      return {
        ...state,
        cookieHydrate: true
      }

    case t.VERSION_MISMATCH:
      return {
        ...state,
        versionMisMatch: !state.versionMisMatch
      }

    default:
      return state
  }
}

export default reducer
