/**
 * State to store if alerts can be shown / have not been dismissed
 */

import t from './actionTypes'
import { alertTypes } from './config/types'

const defaultState = {}

Object.keys(alertTypes).forEach((alert) => {
  defaultState[alert] = true
})

defaultState.currentAction = null

const alert = (state = defaultState, action) => {
  const SHOW = 'SHOW'
  const HIDE = 'HIDE'

  // Not using a switch statement to start because that would be ridiculous amounts of boilerplate code.
  if (t[action.type]) {
    const prefix = action.type.slice(0, 4)
    if ([SHOW, HIDE].indexOf(prefix) > -1) {
      // true means show, false means hide
      const showBoolean = prefix === SHOW
      // since SHOW_ and HIDE_ are the same length,
      const actionKey = action.type.slice(5, action.type.length)
      return Object.assign({}, state, {
        [actionKey]: showBoolean
      })
    } else {
      // Not one of the many alert types, so boilerplate has been avoided.
      // So use normal switch here.
      switch (action.type) {
        case t.SET_CURRENT_ACTION:
          return Object.assign({}, state, {
            currentAction: action.currentAction
          })
        case t.RESET_CURRENT_ACTION:
          return Object.assign({}, state, {
            currentAction: null
          })
        default:
          return state
      }
    }
  } else {
    return state
  }
}

export { defaultState }
export default alert
