import t from './actionTypes'

export const defaultState = {
  isOrderStatusLoading: false,
  hasBeenFirstInQueue: false,
  statusData: {
    deliveryExpectedAt: '',
    deliveryExpectedStartAt: '',
    deliveryExpectedEndAt: '',
    deliveredAt: '',
    firstInQueue: '',
    status: ''
  },
  trackers: []
}

const orderStatus = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_ORDER_STATUS:
      return defaultState

    case t.REQUEST_ORDER_STATUS:
      return {
        ...state,
        isOrderStatusLoading: true
      }

    case t.RECEIVE_ORDER_STATUS:
      return {
        ...state,
        isOrderStatusLoading: false
      }

    case t.SET_HAS_BEEN_FIRST_IN_QUEUE:
      return {
        ...state,
        hasBeenFirstInQueue: true
      }

    case t.SET_ORDER_STATUS_DATA:
      return {
        ...state,
        statusData: action.payload
      }

    case t.SET_ORDER_STATUS_TRACKER_DATA:
      return {
        ...state,
        trackers: action.payload
      }

    default:
      return state
  }
}
export default orderStatus
