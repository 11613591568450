import { DEFAULT_MENU_SLUG } from '@helpers/constants'

import t from './actionTypes'

import type { CartReducerState, CartActionPayload } from './types'


const generateCartState = ({
  key,
  value,
  state,
  payload
}: {
  key: string
  value: any
  state: CartReducerState
  payload: { menuSlug?: string }
}) => {
  const menuSlug = payload.menuSlug || DEFAULT_MENU_SLUG
  return {
    ...state,
    [menuSlug]: {
      // We need to ensure every cart gets initialized with the same defaults, no matter what.
      ...defaultState.default,
      ...state[menuSlug],
      [key]: value
    }
  }
}

export const defaultState: CartReducerState = {
  [DEFAULT_MENU_SLUG]: {
    products: [], // cart items (id, quantity, catalogItemId)
    productsList: [], // server products (full product)
    id: null,
    sizeMismatch: false,
    expired: false,
    message: null
  }
}

const cart = (state = defaultState, { type, payload = {} }: { type: string; payload: Partial<CartActionPayload> }) => {
  switch (type) {
    case t.REQUEST_CART_PRODUCTS:
      // Nothing to do here, loading change is dispatched by the action
      return state

    case t.UPDATE_CART_PRODUCTS:
      return generateCartState({
        key: 'productsList',
        value: payload.products,
        state,
        payload
      })

    case t.RECEIVE_CART_PRODUCTS:
      return generateCartState({
        key: 'productsList',
        value: payload.products,
        state,
        payload
      })

    case t.SET_CART_ID:
      return generateCartState({
        key: 'id',
        value: payload.id,
        state,
        payload
      })

    case t.SET_CART_ITEMS:
      return generateCartState({
        key: 'products',
        value: payload.products,
        state,
        payload
      })

    case t.SET_CART_MESSAGE:
      return generateCartState({
        key: 'message',
        value: payload.message,
        state,
        payload
      })

    case t.CLEAR_CART_MESSAGE:
      return generateCartState({
        key: 'message',
        value: null,
        state,
        payload
      })

    case t.CART_SIZE_MISMATCH:
      return generateCartState({
        key: 'sizeMismatch',
        value: payload.value,
        state,
        payload
      })

    case t.CART_EXPIRED:
      return generateCartState({
        key: 'expired',
        value: payload.value,
        state,
        payload
      })

    case t.CLEAR_CART:
      return {
        ...defaultState
      }

    default:
      return state
  }
}

export default cart
